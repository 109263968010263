@font-face {
    font-family: Inter;
    src: url("./assets/fonts/Inter-Light.ttf");
    font-weight: 300;
}

@font-face {
    font-family: Inter;
    src: url("./assets/fonts/Inter-Regular.ttf");
    font-weight: 400;
}

@font-face {
    font-family: Inter;
    src: url("./assets/fonts/Inter-Medium.ttf");
    font-weight: 500;
}

@font-face {
    font-family: Inter;
    src: url("./assets/fonts/Inter-SemiBold.ttf");
    font-weight: 600;
}

@font-face {
    font-family: Inter;
    src: url("./assets/fonts/Inter-Bold.ttf");
    font-weight: 700;
}

body {
    margin: 0;
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
